import * as React from 'react';
import PreviewPage from "./preview-page"
import Pages from "./pages"
import NextPage from "./next-page"

const Paginate = ({ setResult, setError, baseURL, paginate }) => {  
    
  if (paginate !== undefined) {
    return (
      <div className="section flex justify-center mt-10">
        <PreviewPage setResult={setResult} setError={setError} baseURL={baseURL} page={paginate.preview}></PreviewPage>
        <Pages setResult={setResult} setError={setError} baseURL={baseURL} pages={paginate.pages}></Pages>
        <NextPage setResult={setResult} setError={setError} baseURL={baseURL} page={paginate.next}></NextPage>
      </div>
    )
  }else {
    return (
        <div></div>
    )
  }
}

export default Paginate;