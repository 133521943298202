import React, { useState } from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";
import TextField from '@mui/material/TextField';
import SubmitButton from "./elements/SubmitButton";

const SearchForm = ({ setResult, setError, siteContent }) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const languages = {
    en: "en_US",
    fr: "fr_FR",
    de: "de_DE",
  }
  const langLiferay = languages[siteContent.language];
    
  function onChange(event) {
    if(event.target.value !== ""){
      setUrl('/o/numerique-responsable-ws/search/get-journal-articles/'+event.target.value+'/'+langLiferay+'/0/'+siteContent.delta)
    }else{
      setUrl('')
    }
  }
    
  function keypress(event) {
    if (event.key === "Enter") {
      submit();
    }
  }
    
  function submit() {
    if(url.length > 0){
      fetch(siteContent.baseURL + url)
      .then(response => response.json())
      .then(
        (result) => {
          result.response.forEach(function(article) {
              article.slug = string_to_slug(article.slug);
              article.thumbnailImage = siteContent.baseURL + article.thumbnailImage
          });
          setResult(result);
        },
        (error) => {
          setError(error);
        }
      );
    }else{
      setResult({});
    }
  }

  function string_to_slug(str) {
    if(!str) return "";
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
  
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes
  
    return str;
  }

  return (
    <div>
      <div className="mb-5">
        <span>Quel contenu recherchez-vous ?</span>
        <p className='italic mt-2 text-slate-200'>La recherche se limite aux contenus ressources uniquement</p>
      </div>
      <div>
        <TextField fullWidth onInput={onChange} onKeyPress={keypress} id="keywords" label={t("form_keyword")} variant="standard"  />
      </div>
      
      <div className="mt-10">
        <SubmitButton submit={submit} >{t("form_search")}</SubmitButton>
      </div>
    </div>
  )
}

export default SearchForm;