import React, { useState } from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout"
import SEOComponent from '../components/seo'
import TitleAltComponent from "../components/title-alt"
import SearchForm from "../components/search-form";
import SearchResult from "../components/search-result"
import { graphql } from 'gatsby'

const SearchPage = ({ data }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent;
  const [result, setResult] = useState({});
  const [error, setError] = useState("");

  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEOComponent title={t("menu_search")} />
        <TitleAltComponent title={t("menu_search")} description=""></TitleAltComponent>
        <div className="px-5 section">
          <SearchForm setResult={setResult} setError={setError} siteContent={siteContent}></SearchForm>
          <SearchResult setResult={setResult} setError={setError} baseURL={siteContent.baseURL} result={result} error={error}></SearchResult>
        </div>
      </Layout>
    </main>
  );
}

export default SearchPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteContent(language: {eq: $language}) {
      editionYear
      programYear
      mediaKit
      language
      cookieURL
      personalDataURL
      baseURL
      delta
      banner
    }
  }
`