import * as React from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";
import PreviewArticleList from "./article/preview-article-list"
import Paginate from "./paginate/paginate"

const SearchResult = ({ setResult, setError, baseURL, result, error }) => {
  const { t } = useTranslation();
  if (error) {
    return (
      <div className="section my-10">
        {t("error")} {error.message}
      </div>
    );
  } else {
    if (result.nbResult !== undefined && result.nbResult > 0) {
      return (
        <div className="section my-10">
          <div className="section mb-10">{result.nbResult} {t("results")}</div>
          <PreviewArticleList articles={result.response}></PreviewArticleList>
          <Paginate setResult={setResult} setError={setError} baseURL={baseURL} paginate={result.paginate}></Paginate>
        </div>
      );
    } else {
      return (
        <div className="section my-10">
          {t("no_result")}
        </div>
      );
    }
  }
}

export default SearchResult;