import { Link } from "gatsby-plugin-react-i18next";
import * as React from "react";
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from "@mui/material";
import SocialComponent from "./socials";

const PreviewArticle = ({ index, article, inHome }) => {
    const [showShare, setShare] = React.useState(false);
    return (
        <div>
            <Link to={`/article/${article.slug}`}>
                <div className="edition-article">
                    <img loading="lazy" src={article.thumbnailImage} alt={"Thumbnail of " + article.title} className='thumbnailImage' aria-labelledby={`article-title-${index}`} aria-describedby={`article-description-${index}`}></img>
                    {inHome ? <h3 id={`article-title-${index}`} >{article.title}</h3> : <h2 id={`article-title-${index}`} >{article.title}</h2>}
                    <p id={`article-description-${index}`}>
                        {article.shortDescription}
                    </p>
                    <div className="flex gap-3 mt-3 flex-wrap" >
                        {article.categories.map((cat,i) => (<span key={i} className="label-category">{cat}</span>))}
                    </div>


                </div>
            </Link>
            <div className="flex  gap-6 mt-3" >
                <IconButton color="primary" aria-label="toogle share button" onClick={() => setShare(!showShare)}>
                    <ShareIcon htmlColor="white"></ShareIcon>
                </IconButton>

                <SocialComponent className={`flex gap-6 share-section items-center justify-center ${showShare ? "" : "hidden"}`} article={article} fontSize="large">
                </SocialComponent>
            </div>
        </div>


    )
}

export default PreviewArticle;