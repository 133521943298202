import * as React from "react"
import { Button } from "@mui/material";

const PageButton = ({ setResult, setError, baseURL, url, className, icon, children }) => {

  function string_to_slug(str) {
      if(!str) return "";
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
    
      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaeeeeiiiioooouuuunc------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
    
      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
    
      return str;
  }
    
    function changePage() {
      if(url.length > 0){
        fetch(baseURL + url)
        .then(response => response.json())
        .then(
          (result) => {
            result.response.forEach(function(article) {
                article.slug = string_to_slug(article.slug);
                article.thumbnailImage = baseURL + article.thumbnailImage
            });
            setResult(result);
          },
          (error) => {
            setError(error);
          }
        );
      }
    }
    
    if (icon !== undefined) {
      return (
        <Button className={className} startIcon={icon} onClick={changePage}></Button>
      );
    }else {
      if (url !== undefined) {
        return (
          <Button className={className} onClick={changePage}>{children}</Button>
        );
      }else {
        return (
          <Button variant="contained" color="secondary" className={className}>{children}</Button>
        );
      }
    }
}

export default PageButton;
