import * as React from "react"
import PreviewArticle from "../preview-article";


const PreviewArticleList = ({ articles }) => {
    return (
        <div className="section grid grid-cols-articles justify-items-center gap-20">
            {articles.map((article, i) => <PreviewArticle key={i} index={i} article={article} inHome={false}></PreviewArticle>)}
        </div>
    )
}

export default PreviewArticleList;