import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next";
import PageButton from "../elements/PageButton";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

const PreviewPage = ({ setResult, setError, baseURL, page }) => {
  const { t } = useTranslation();
  var className = "font-normal text-white";
  if (page === undefined) {
    className = "font-normal Mui-disabled";
  }

  return (
    <div className="inline-block flex">
      <PageButton setResult={setResult} setError={setError} baseURL={baseURL} url={page} className={className} icon={<ArrowBackIosOutlinedIcon></ArrowBackIosOutlinedIcon>}>{t("previous")}</PageButton>
    </div>
  );
}

export default PreviewPage;

