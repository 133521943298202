import * as React from "react"
import Page from "./page"

const Pages = ({ setResult, setError, baseURL, pages }) => {
  return (
    <div className="inline-block">        
      {pages.map((page) => <Page setResult={setResult} setError={setError} baseURL={baseURL} page={page}></Page>)}
    </div>
  );
}

export default Pages;

