import * as React from "react"
import PageButton from "../elements/PageButton";

const Page = ({ setResult, setError, baseURL, page }) => {
  var className = "font-normal text-white";
  if (page.URL === undefined) {
    className = "font-normal text-black btn-radius";
  }
  
  return (
      <PageButton setResult={setResult} setError={setError} baseURL={baseURL} url={page.URL} className={className}>{page.label}</PageButton>
  );
}

export default Page;